// src/plugins/toast-plugin.ts

import { App, createApp, inject } from 'vue';
import ToastComponent from './ToastComponent.vue';

interface ToastOptions {
  message: string;
  duration?: number;
}

interface Toast {
  show(options: ToastOptions): void;
}

const ToastSymbol = Symbol();

export const createToast = (): Toast => {
  const toastApp = createApp(ToastComponent);
  const component = toastApp.mount(document.createElement('div'));
  document.body.appendChild(component.$el);

  return {
    show(options: ToastOptions) {
      (component as any).add(options);
    }
  };
};

export const ToastPlugin = {
  install(app: App) {
    const toast = createToast();
    app.config.globalProperties.$toast = toast;
    app.provide(ToastSymbol, toast);
  }
};

export const useToast = (): Toast => {
  const toast = inject<Toast>(ToastSymbol);
  if (!toast) throw new Error('Toast plugin not installed');
  return toast;
};
