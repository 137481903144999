import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    players: 'PLAYERS',
    wallet: {
      connect: 'Connect Wallet',
      connected: 'Wallet connected',
      disconnected: 'Wallet disconnected',
      connectError: 'Failed to connect wallet. Please try again.',
      disconnectSuccess: 'Wallet disconnected successfully',
    },
    game: {
      cashout: 'Cashout',
      cashoutNotImplemented: 'Cashout functionality not implemented yet',
      deposit: {
        success: 'Deposit successful',
        failed: 'Deposit failed. Please try again.',
        invalidAmount: 'Please enter a valid amount',
        exceedLimit: 'Deposit cannot exceed {max} TON per transaction',
      },
      withdraw: {
        success: 'Withdrawal successful',
        failed: 'Withdrawal failed. Please try again.',
      },
      betsClosing: 'BETS CLOSING IN',
      launching: 'LAUNCHING IN',
      seconds: 'SECS',
      inProgress: 'GAME IN PROGRESS',
      settling: 'SETTLING...',
      connecting: 'CONNECTING...',
      processing: 'PROCESSING...',
      chart: {
        disconnected: 'Disconnected. Waiting for connection...',
        crashAt: 'CRASH AT {percentage}%',
        cashoutAt: 'CASHOUT AT {multiplier}% ',
      },
    },
    errors: {
      walletRequired: 'Please connect your wallet first',
    },
    sidebar: {
      overview: 'Overview',
      closeMenu: 'Close menu',
      all: 'All',
      top: 'Top',
      noData: {
        title: 'No data available',
        description: 'Waiting for the game to start or players to join.',
      },
      tableHeaders: {
        player: 'Player',
        bet: 'Bet',
        cashout: 'Cash-out',
        payout: 'Payout',
        round: 'Round',
        crashPoint: 'Crash Point',
        bestCashOut: 'Best Cash Out',
        bestPlayer: 'Best Player',
        totalPlayers: 'Total Players',
        autoCashout: 'AutoCashout',
        status: 'Status',
      },
      stats: {
        players: 'Players',
        round: 'Round',
        online: 'Online',
      },
    },
    navbar: {
      title: 'BONE',
      connect: 'CONNECT',
      closeMenu: 'Close menu',
      menuItems: {
        leaderboard: 'Leaderboard',
        status: 'Status',
        gameInfo: 'Game Info',
      },
      home: 'Home',
      friends: 'frends',
    },
    card: {
      ping: 'Ping',
      pingTooltip:
        'Ping represents the network latency. Lower values indicate better connection quality.',
      amount: 'AMOUNT',
      gameInfo: {
        title: 'Game Info',
        betRange:
          'Bet range: <span class="text-green-400">0.01</span> - <span class="text-yellow-400">10</span> TON',
        multiplierAndCashout:
          'Set multiplier (<span class="text-green-400">1.01x</span> - <span class="text-yellow-400">1000x</span>) for auto  cashout',
      },
      custom: 'Custom',
      play: 'PLAY',
      game: 'GAME',
      now: 'NOW',
      cashout: 'CASH-OUT',
      deposit: 'Deposit',
    },
    walletDrawer: {
      loading: 'loading...',
      title: 'Wallet',
      close: 'Close menu',
      copyButton: 'Copy',
      copied: 'Copied!',
      copyFailed: 'Failed',
      disconnectWallet: 'DISCONNECT',
      connectWallet: 'CONNECT',
      tonBalance: 'Balance',
      walletAddress: 'Wallet Address',
      inviteFriends: 'Invite Friends',
      inviteReward: 'Earn BONE for inviting friends',
      lastTwentyFourHours: '24h',
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      getHistory: 'History',
      boundAddress: 'Bound Address',
      notBound: 'Not Bound',
      binding: 'Binding',
      bound: 'Bound',
      changeBind: 'Change Binding',
      bind: 'Bind',
    },
    inviteDrawer: {
      title: 'Invite Friends',
      inviteReward: 'Invite Reward',
      points: 'points',
      viewRules: 'View Rules',
      inviteLink: 'Invite Link',
      copyButton: 'Copy',
      copied: 'Copied!',
      copyFailed: 'Copy failed',
      copiedSuccess: 'Address Copied',
      shareText:'Join BONE Community!'
    },
    statusDrawer: {
      title: 'STATUS',
      closeMenu: 'Close menu',
      gameStats: {
        title: 'Last 100 Games (global)',
        biggestWin: 'Biggest Win',
        numberOfRugs: 'Number of Rugs',
        averageCrash: 'Average Crash',
        medianCrash: 'Median Crash',
      },
      crashDistribution: {
        title: 'Crash Distribution',
        ranges: {
          '100-200': '100-200%',
          '200-400': '200-400%',
          '400-600': '400-600%',
          '600-800': '600-800%',
          '800-1000': '800-1000%',
          '1000plus': '1000+%',
        },
      },
    },
    gameInfoDrawer: {
      title: 'Game Info',
      closeMenu: 'Close menu',
      howToPlay: {
        title: 'How to Play',
        steps: {
          0: 'Place a bet "trade" and watch the multiplier increase!',
          1: 'Take profits before the coin gets rugged to win',
        },
      },
      moreInfo: {
        title: 'More Info',
        maxWin: {
          title: 'Max win:',
          content:
            'During the Beta the max win is {maxMultiplier}x your wager size, currently capped at {maxSolWin} per player per round. The max total SOL reward for all players combined per round is capped at {maxTotalSolReward}.',
        },
        betSizes: {
          title: 'Min and max bet-sizes:',
          content:
            'The min betsize is {minBet} and the max betsize is {maxBet}.',
        },
      },
      strategy: {
        title: 'Strategy',
        autoCashOut: {
          title: 'Auto Cash out:',
          content:
            "Auto cash-out allows you to set a predetermined multiplier at which your bet will automatically cash out. This can help manage risk and ensure you don't miss the cash-out point.",
        },
      },
      algorithm: {
        title: 'Algorithm',
        description:
          'The result is determined when the bets open for a new round, before any players join. The code has no knowledge about the house or players. We use a certified node.js server generating the random value.',
        code: `const gameResult = (): number => {
  const rng = Math.random();
  const X = {baseMultiplier} / ({subFactor} - rng);
  const result = Math.floor(X);
  return Math.max({minMultiplier}, result / {divFactor});
};`,
      },
    },
    modal: {
      confirmTitle: 'Confirm Bet',
      close: 'Close',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
  },
  // Add translations for other languages here
}

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})
