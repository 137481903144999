<!-- src/components/ToastComponent.vue -->
<template>
  <div class="fixed top-5 right-5 space-y-2 z-50">
    <transition-group
      enter-active-class="transition ease-out duration-300"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div 
        v-for="toast in toasts"
        :key="toast.id"
        class="flex items-center w-full max-w-xs p-4 space-x-4 rtl:space-x-reverse text-gray-200 bg-[#2A2A2A] divide-x rtl:divide-x-reverse divide-gray-600 rounded-lg shadow-lg border border-gray-600"
        role="alert"
      >
        <svg class="w-5 h-5 text-blue-400 rotate-45" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 17 8 2L9 1 1 19l8-2Zm0 0V9"/>
        </svg>
        <div class="ps-4 text-sm font-normal">{{ toast.message }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

interface Toast {
  id: number;
  message: string;
}

const toasts = ref<Toast[]>([]);

const add = (options: { message: string; duration?: number }) => {
  const id = Date.now();
  const toast = { id, message: options.message };
  toasts.value.push(toast);
  setTimeout(() => {
    remove(id);
  }, options.duration || 3000);
};

const remove = (id: number) => {
  const index = toasts.value.findIndex(t => t.id === id);
  if (index !== -1) {
    toasts.value.splice(index, 1);
  }
};

defineExpose({ add });
</script>