import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './App.vue'
import router from './router/index'
import "./css/tailwindcss.css"
import { i18n } from './i18n'

import { createPinia } from 'pinia'

import { ToastPlugin } from './plugins/toast-plugin';
import '@mdi/font/css/materialdesignicons.css';

const app = createApp(App);

// 注册所有 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.use(ElementPlus)
app.use(router)
app.use(ToastPlugin);
app.use(createPinia())
app.use(i18n)
app.mount('#app')