import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import generatedRoutes from '~pages'

const InvitePage = () => import("../components/codeeditor/code/5.vue/6.vue-router的基本使用/InvitePage.vue");
const FileExplorer = () => import("../components/webcontainer/File/FileExplorer.vue");


const customRoutes: Array<RouteRecordRaw> = [
  // 处理 /invite/ABC123 形式的邀请链接
  {
    path: '/invite/:code',
    name: 'InviteWithParam',
    component: InvitePage
  },
  // 处理 /signup?invite=ABC123 形式的邀请链接
  {
    path: '/signup',
    name: 'SignupWithQuery',
    component: InvitePage
  },
  {
    path: '/files',
    name: 'FileExplorer',
    component: FileExplorer,
    props: (route) => ({ selectedFileName: route.query.file })
  }
];

const routes = [...generatedRoutes, ...customRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;